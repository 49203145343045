import React from "react"
import { graphql, PageProps } from "gatsby"

import { LocaleLink as Link } from "../../contexts/i18n"
import Layout from "../../layout"
import SEO from "../../components/SEO"
import List from "../../components/List"
import Textblock from "../../components/Textblock"
import * as Routes from "../../../routes"

interface DataType {
  articles: Contentful.Cxn<Contentful.Article>
}

const BlogPage = ({ data }: PageProps<DataType>) => (
  <Layout narrow>
    <Textblock>
      <SEO title="Blog" />
      <h1>Blog</h1>
      <List className="blog-list">
        {data.articles.edges.map((article) => (
          <Link to={Routes.blogPath(article.node.slug)} title={article.node.title} className="blog-card-link">
            <div className="blog-card">
              <h3 className="blog-title">{article.node.title}</h3>
            </div>
          </Link>
        ))}
      </List>
    </Textblock>
  </Layout>
)

export default BlogPage

export const pageQuery = graphql`
  query ($locale: String!) {
    articles: allContentfulArticle(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`
